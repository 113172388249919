<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="organisations-edit">
      <div class="mb-4">
        <v-alert
          v-if="organisation.id !== loggedInOrganisationId"
          type="info"
          outlined
          class="my-8"
        >
          <div class="mb-4 text-center">
            {{ $t("organisations.dataPrivacyWarning") }}
          </div>
          <template v-if="hasImpersonationsFromOrganisation(organisation.id)">
            <div class="mb-4 text-center">
              {{ $t("organisations.dataPrivacyWarningHasImpersonation") }}
            </div>
            <div class="d-flex justify-center">
              <v-btn
                v-bind="propsButtonAction"
                @click.prevent="clickImpersonate(organisation.id)"
              >
                <v-icon>$impersonation</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-else>
            <div class="text-center">
              {{ $t("organisations.dataPrivacyWarningHasImpersonation") }}
            </div>
          </template>
        </v-alert>

        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.name')"
              v-model="editName"
              :error-messages="editNameErrors"
              @input="$v.editName.$touch()"
              @blur="$v.editName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <div>
              <div class="label-show">
                {{ $t("organisations.levels.label") }}:
              </div>
              <OrganisationLevelFieldRO
                :levelValue="editLevel"
              ></OrganisationLevelFieldRO>
            </div>
            <!-- <OrganisationLevelPickerSelect
              :pickerValue="editLevel"
              @pickerChanged="
                editLevel = $event;
                $v.editLevel.$touch();
              "
              @blur="$v.editLevel.$touch()"
              :errorMessages="editLevelErrors"
            ></OrganisationLevelPickerSelect> -->
          </div>

          <template v-if="organisation.id === loggedInOrganisationId">
            <div class="formField">
              <v-text-field
                v-bind="propsFormFields"
                :label="$t('common.address')"
                v-model="editAddress"
                :error-messages="editAddressErrors"
                @input="$v.editAddress.$touch()"
                @blur="$v.editAddress.$touch()"
                type="text"
              >
              </v-text-field>
            </div>

            <div class="formField">
              <v-text-field
                v-bind="propsFormFields"
                :label="$t('common.city')"
                v-model="editCity"
                :error-messages="editCityErrors"
                @input="$v.editCity.$touch()"
                @blur="$v.editCity.$touch()"
                type="text"
              >
              </v-text-field>
            </div>

            <div class="formField">
              <v-text-field
                v-bind="propsFormFields"
                :label="$t('common.postalCode')"
                v-model="editPostalCode"
                :error-messages="editPostalCodeErrors"
                @input="$v.editPostalCode.$touch()"
                @blur="$v.editPostalCode.$touch()"
                type="text"
              >
              </v-text-field>
            </div>

            <div class="formField">
              <CountryPickerSelect
                :pickerValue="editCountryIso3166_a2"
                @pickerChanged="editCountryIso3166_a2 = $event"
              >
              </CountryPickerSelect>
            </div>

            <div class="formField">
              <LanguagePickerSelect
                :pickerValue="editLanguageIso639_1"
                @pickerChanged="editLanguageIso639_1 = $event"
              >
              </LanguagePickerSelect>
            </div>

            <div class="formField">
              <v-textarea
                v-bind="propsFormFields"
                :label="$t('common.description')"
                v-model="editDescription"
                :error-messages="editDescriptionErrors"
                @input="$v.editDescription.$touch()"
                @blur="$v.editDescription.$touch()"
                type="text"
              >
              </v-textarea>
            </div>
          </template>

          <!-- TODO: Only the admin of the parent company can change this. -->
          <!-- <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('organisations.maxFileSize')"
              v-model="editMaxFileSize"
              :error-messages="editMaxFileSizeErrors"
              @input="$v.editMaxFileSize.$touch()"
              @blur="$v.editMaxFileSize.$touch()"
              type="text"
            >
            </v-text-field>
            <div class="d-flex align-center">
              <div class="label-show mr-2">
                {{ $t("organisations.totalFileSize") }}:
              </div>
              <span>{{ prettifyDiskSize(organisation.totalFileSize) }}</span>
            </div>
          </div> -->

          <template v-if="organisation.id === loggedInOrganisationId">
            <div class="formField">
              <v-checkbox
                v-bind="propsFormFields"
                v-model="editNotifyCreate"
                :label="$t('organisations.notifyCreate')"
              ></v-checkbox>
            </div>

            <div class="formField">
              <v-checkbox
                v-bind="propsFormFields"
                v-model="editNotifyUpdate"
                :label="$t('organisations.notifyUpdate')"
              ></v-checkbox>
            </div>

            <div class="formField">
              <v-checkbox
                v-bind="propsFormFields"
                v-model="editNotifyRead"
                :label="$t('organisations.notifyRead')"
              ></v-checkbox>
            </div>

            <div class="formField">
              <v-checkbox
                v-bind="propsFormFields"
                v-model="editNotifyDownload"
                :label="$t('organisations.notifyDownload')"
              ></v-checkbox>
            </div>

            <div class="formField">
              <v-checkbox
                v-bind="propsFormFields"
                v-model="editNotifyDelete"
                :label="$t('organisations.notifyDelete')"
              ></v-checkbox>
            </div>
          </template>
        </v-form>
      </div>

      <div class="d-flex mt-8">
        <!-- <v-btn
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          @click="clickRemove"
          data-cy="button-remove"
          >{{ $t("common.remove") }}</v-btn
        > -->
        <v-btn
          v-if="authCanByOrganisationId('editOwn.organisations', objectId)"
          v-bind="propsButton"
          :loading="status.loading"
          :to="{
            name: 'organisations-edit-appearance',
            params: {
              id: this.objectId,
            },
          }"
          data-cy="button-update-appearance"
          >{{ $t("organisations.updateAppearance") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="authCanByOrganisationId('editOwn.organisations', objectId)"
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import MiscUtils from "@/modules/base/miscUtils.mixin";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import FormCommonErrors from "@/modules/base/formCommonErrors.mixin";
import OrganisationLevelFieldRO from "@/modules/organisations/bits/OrganisationLevelFieldRO";
// import OrganisationLevelPickerSelect from "@/modules/organisations/bits/OrganisationLevelPickerSelect";
import CountryPickerSelect from "@/modules/base/bits/CountryPickerSelect";
import LanguagePickerSelect from "@/modules/base/bits/LanguagePickerSelect";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "OrganisationsEdit",
  mixins: [Permissions, ComponentStatus, MiscUtils, FormCommonErrors],
  components: {
    OrganisationLevelFieldRO,
    // OrganisationLevelPickerSelect,
    CountryPickerSelect,
    LanguagePickerSelect,
  },
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    firstFormClick: true,
    changed: false,
    // Edit proxy form data
    editOrganisationId: "",
    editName: "",
    editLevel: null,
    editAddress: "",
    editCity: "",
    editPostalCode: "",
    editDescription: "",
    editCountryIso3166_a2: null,
    editLanguageIso639_1: null,

    editNotifyCreate: false,
    editNotifyUpdate: false,
    editNotifyRead: false,
    editNotifyDownload: false,
    editNotifyDelete: false,

    editMaxFileSize: null,
  }),
  validations: {
    editName: {
      required,
      maxLength: maxLength(1000),
    },
    editLevel: {
      required,
    },
    editAddress: {
      maxLength: maxLength(1000),
    },
    editCity: {
      maxLength: maxLength(1000),
    },
    editPostalCode: {
      maxLength: maxLength(1000),
    },
    editCountryIso3166_a2: {},
    editLanguageIso639_1: {},
    editDescription: {},
    editMaxFileSize: {},
    editNotifyCreate: {},
    editNotifyUpdate: {},
    editNotifyRead: {},
    editNotifyDownload: {},
    editNotifyDelete: {},
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    // 2) When the organisation id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the organisation data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    organisation() {
      return this.$store.getters["organisations/readById"](this.objectId);
    },
    loggedInOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    editLevelErrors() {
      const errors = [];
      if (!this.$v.editLevel.$dirty) return errors;
      if (!this.$v.editLevel.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editMaxFileSizeErrors() {
      const errors = [];
      // if (!this.$v.editLevel.$dirty) return errors;
      // if (!this.$v.editLevel.required) {
      //   errors.push(this.$t("common.errors.required"));
      // }
      return errors;
    },
  },
  watch: {
    // 3) If the organisation id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editOrganisationId = this.objectId;
          this.$store
            .dispatch("organisations/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    // 4) In case we load new organisation data (and we are not in the middle of
    // editing) we update our "proxy" form editable variables to reflect
    // what we have just loaded.
    organisation: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {},
  methods: {
    inputChanged() {
      this.changed = true;
    },
    hasImpersonationsFromOrganisation(organisationId) {
      return this.$store.getters["auth/hasImpersonationsFromOrganisation"](
        organisationId
      );
    },
    refreshFormData(value) {
      let newValue = this.organisation;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        this.editName = newValue.name;
        this.editLevel = newValue.level;
        this.editAddress = newValue.address;
        this.editCity = newValue.city;
        this.editPostalCode = newValue.postalCode;
        this.editCountryIso3166_a2 = newValue.countryIso3166_a2;
        this.editLanguageIso639_1 = newValue.languageIso639_1;
        this.editDescription = newValue.description;
        this.editMaxFileSize = this.prettifyDiskSize(newValue.maxFileSize);
        this.editNotifyCreate = newValue.notifyCreate;
        this.editNotifyUpdate = newValue.notifyUpdate;
        this.editNotifyRead = newValue.notifyRead;
        this.editNotifyDownload = newValue.notifyDownload;
        this.editNotifyDelete = newValue.notifyDelete;
      }
    },
    clickUpdate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        this.sendUpdate();
      }
    },
    sendUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editOrganisationId,
        name: this.editName,
        level: this.editLevel,
        address: this.editAddress,
        city: this.editCity,
        postalCode: this.editPostalCode,
        countryIso3166_a2: this.editCountryIso3166_a2,
        languageIso639_1: this.editLanguageIso639_1,
        description: this.editDescription,
        notifyCreate: this.editNotifyCreate,
        notifyUpdate: this.editNotifyUpdate,
        notifyRead: this.editNotifyRead,
        notifyDownload: this.editNotifyDownload,
        notifyDelete: this.editNotifyDelete,
      };
      let newMaxFileSize = this.parseDiskSize(this.editMaxFileSize);
      if (!isNaN(newMaxFileSize)) {
        payload.maxFileSize = newMaxFileSize;
      }
      this.$store
        .dispatch("organisations/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
    clickRemove() {
      this.setStatusDeleting();
      this.$store
        .dispatch("organisations/delete", this.editOrganisationId)
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          this.$router.push({ name: "organisations-index" });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
    clickImpersonate(id) {
      let impersonations =
        this.$store.getters["auth/getImpersonationsFromById"](id);
      // TODO: handle when there is more than one possible case.
      this.$store
        .dispatch("auth/impersonateTo", {
          to_user_id: impersonations[0]["to_user_id"],
          to_organisation_id: impersonations[0]["to_organisation_id"],
        })
        .then((/* result */) => {
          this.setStatusReadSuccess();
          if (
            this.$route.name === "home" ||
            this.$route.name === "documents-index"
          ) {
            this.$router.go();
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
